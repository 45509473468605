import clsx from 'clsx'
import { useMemo, useRef } from 'react'
import { useDrag, useDrop } from 'react-dnd'
import { ColourPicker } from 'admin/pages/Settings/Pipeline/ColourPicker'
import { EllipsesActions } from 'components/EllipsesActions'
import { Flex } from 'components/Flex'
import { Icon, IconName } from 'components/Icon'
import { LoanSubstatus } from 'types'

interface Props {
  substatus: LoanSubstatus
  onEdit: (id: string) => void
  onDelete: (id: string) => void
  onColourChange: (data: { colour: string; id: string }) => void
  onDrop: (dragSubstatus: LoanSubstatus, targetSubstatus: LoanSubstatus) => void
}

function SubstatusItem({
  substatus,
  onEdit,
  onDelete,
  onDrop,
  onColourChange,
}: Props) {
  const ref = useRef<HTMLDivElement>(null)

  const dragType = useMemo(
    () => `substatus-${substatus.status}`,
    [substatus.status]
  )

  const [{ isOver }, drop] = useDrop<LoanSubstatus, void, { isOver: boolean }>({
    accept: dragType,
    collect: (monitor) => ({
      isOver: monitor.isOver(),
    }),
    drop(item: LoanSubstatus) {
      onDrop(item, substatus)
    },
  })

  const [{ isDragging }, drag] = useDrag({
    type: dragType,
    item: () => substatus,
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
  })

  drag(drop(ref))

  return (
    <Flex
      ref={ref}
      key={substatus.id}
      justifyContent="space-between"
      alignItems="center"
      className={clsx(
        'border-grey-100 border-solid border-0 border-b py-4',
        isOver && '!border-blue-100 border-b-[3px]',
        isDragging && 'hidden'
      )}
    >
      <Flex>
        <Icon
          name={IconName.dndHandler}
          className="flex-shrink-0 text-grey-500 cursor-move"
        />
        <Flex>{substatus.name}</Flex>
      </Flex>
      <Flex>
        <ColourPicker
          colour={substatus.colour}
          onSelect={(colour: string) => {
            onColourChange({ colour, id: substatus.id as string })
          }}
        />
        <EllipsesActions>
          <EllipsesActions.Item
            icon
            onSelect={() => {
              onEdit(substatus.id as string)
            }}
          >
            <Icon name={IconName.edit} />
            Edit Substage
          </EllipsesActions.Item>
          <EllipsesActions.Item
            icon
            onSelect={() => {
              onDelete(substatus.id as string)
            }}
            variant="danger"
          >
            <Icon name={IconName.delete} />
            Delete Substage
          </EllipsesActions.Item>
        </EllipsesActions>
      </Flex>
    </Flex>
  )
}

export { SubstatusItem }
