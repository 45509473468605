import { get } from 'lodash'
import { useCallback, useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { useAbilitiesContext } from 'admin/components/Abilities/AbilitiesContext'
import { PageTop } from 'admin/components/PageTop'
import { MainContent } from 'admin/components/layout/MainContent'
import {
  useInvestor,
  useUpdateInvestorOwners,
  useDeleteInvestor,
} from 'admin/hooks/use-investors'
import { useFieldsByPersonId } from 'admin/hooks/use-person-fields'
import { useReport } from 'admin/hooks/use-report'
import { pathTo } from 'admin/path-to'
import { InvestorDetails } from 'admin/services/api/investors'
import { AccountTypeBadge } from 'components/Badge'
import PersonStatusBadge from 'components/Badge/PersonStatusBadge'
import { EllipsesActions } from 'components/EllipsesActions'
import { Icon, IconName } from 'components/Icon'
import { LinkEmail } from 'components/LinkEmail'
import { PageLoader } from 'components/LoaderOverlay'
import { ModalDelete } from 'components/Modal/Delete'
import { PageTopFields } from 'components/PageTopFields'
import { Tabs } from 'components/Tabs'
import { useSession } from 'hooks/use-session'
import { formatUsd } from 'utils/currency'
import TabDocuments from './TabDocuments'
import TabGeneral from './TabGeneral'
import TabInvestments from './TabInvestments'
import TabStatements from './TabStatements'
import TabTransactions from './TabTransactions'
import styles from './styles.module.scss'

function Investor() {
  const { user } = useSession()
  const ability = useAbilitiesContext()
  const settings = get(user, 'client.settings', {
    autoInvestorAccounting: undefined,
  })
  const [secure, setSecure] = useState<boolean>()
  const [investor, setInvestor] = useState<InvestorDetails>()
  const { id, tab } = useParams() as { id: string; tab: string }
  const { data } = useInvestor({ id, params: { secure } })
  const { mutate: updateOwners } = useUpdateInvestorOwners()
  const { data: cashBalances } = useReport(
    `investor/${id}/cash-balances`
  ) as unknown as {
    data: { cash: string; availableCash: string }
  }
  const { data: principalInvested } = useReport(
    `investor/${id}/principal-invested-balance`
  )
  const { data: fields } = useFieldsByPersonId({
    personType: 'investor',
    personId: id,
  })
  const navigate = useNavigate()

  const [isModalVisible, setIsModalVisible] = useState(false)

  const { mutate: deleteInvestor } = useDeleteInvestor()

  const handleClickDelete = () => {
    if (investor) {
      deleteInvestor(investor.id, {
        onSuccess: () => navigate(pathTo('investors')),
      })
    }
  }

  useEffect(() => {
    if (investor && id !== investor.id) {
      setInvestor(undefined)
      setSecure(false)
    }
  }, [id, investor])

  useEffect(() => {
    if (data && fields) {
      setInvestor(data)
    }
  }, [data, fields])

  const showTotalCash = settings.autoInvestorAccounting !== 'Yes'

  const handleOwnersChange = useCallback(
    (owners: string[]) => {
      updateOwners({ id, owners })
    },
    [id, updateOwners]
  )

  return (
    <MainContent>
      {investor ? (
        <>
          <PageTop
            title={investor.name}
            breadcrumbs={{ title: 'Investors', link: pathTo('investors') }}
            owners={investor.owners || []}
            onOwnersChange={handleOwnersChange}
          />
          <div className={styles.badges}>
            <AccountTypeBadge
              type={investor.type}
              isAccount={investor.isAccount}
            />
            <PersonStatusBadge status={investor.status} />
          </div>
          <PageTopFields
            fields={[
              { key: 'ID', value: investor.id },
              ...(showTotalCash
                ? [
                    {
                      key: 'Cash',
                      value: formatUsd(cashBalances?.cash || 0),
                    },
                    {
                      key: 'Available Cash',
                      value: formatUsd(cashBalances?.availableCash || 0),
                    },
                  ]
                : []),
              { key: 'Invested', value: formatUsd(principalInvested || 0) },
              ...(investor?.type === 'individual'
                ? [
                    {
                      key: 'Email',
                      value: LinkEmail({ email: investor.email }),
                    },
                  ]
                : []),
            ]}
          />
          <Tabs
            defaultActiveId={tab || 'general'}
            afterTabs={
              <EllipsesActions
                trigger={<Icon name={IconName.ellipses} size="md" />}
              >
                <EllipsesActions.Item
                  icon
                  onSelect={() => setIsModalVisible(true)}
                  className="text-red-100"
                >
                  <Icon name={IconName.delete} />
                  Delete
                </EllipsesActions.Item>
              </EllipsesActions>
            }
            onTabSelect={(tabId) => navigate(pathTo('investorTab', id, tabId))}
            className={styles.tabs}
          >
            <Tabs.Pane tab="General" id="general">
              <TabGeneral
                investor={investor}
                fields={fields}
                onSecure={setSecure}
              />
            </Tabs.Pane>
            <Tabs.Pane tab="Investments" id="investments">
              <TabInvestments investor={investor} />
            </Tabs.Pane>
            {ability.can('list', 'loan-documents') ? (
              <Tabs.Pane tab="Documents" id="documents">
                <TabDocuments investor={investor} />
              </Tabs.Pane>
            ) : null}
            <Tabs.Pane tab="Transactions" id="transactions">
              <TabTransactions investor={investor} />
            </Tabs.Pane>
            <Tabs.Pane tab="Statements" id="statements">
              <TabStatements investor={investor} />
            </Tabs.Pane>
          </Tabs>
          {isModalVisible && (
            <ModalDelete
              resource="investor"
              name={investor.name}
              onDelete={handleClickDelete}
              onCancel={() => setIsModalVisible(false)}
            />
          )}
        </>
      ) : (
        <PageLoader />
      )}
    </MainContent>
  )
}

export { Investor }
