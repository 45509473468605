import { useMutation } from '@tanstack/react-query'
import { Formik } from 'formik'
import { Link } from 'react-router-dom'
import { Button } from 'components/Button'
import { Field, Form } from 'components/Form'
import { Header } from 'components/Header'
import { Icon, IconName } from 'components/Icon'
import { Logo } from 'components/Logo'
import { Text } from 'components/Text'
import { subdomain } from 'constants/domain'
import { resetPassword } from 'services/api/session'
import { createScheme, email, mergeRules, required } from 'utils/schemas'
import styles from './styles.module.scss'

interface Props {
  pathTo: any
}

type FormValues = {
  email: string
}

const ForgotPasswordSchema = createScheme({
  email: mergeRules(email, required),
})

const initialValues: FormValues = {
  email: '',
}

function ForgotPassword({ pathTo }: Props) {
  const { mutate, isPending, isSuccess } = useMutation({
    mutationFn: resetPassword,
  })
  const handleSubmit = ({ email }: FormValues) => {
    mutate({
      email,
      subdomain: subdomain,
    })
  }

  return (
    <div className={styles.container}>
      <div className={styles.formBox}>
        <Logo
          type="statement"
          to={pathTo('signIn')}
          logoClassName="max-h-20 max-w-40"
        />
        {isSuccess ? (
          <div className={styles.form}>
            <Header variant="h1" className={styles.title}>
              Your password reset email has been sent!
            </Header>
            <Text className={styles.description}>
              You have sent a password reset email to your email address.
            </Text>
          </div>
        ) : (
          <Formik
            initialValues={initialValues}
            validationSchema={ForgotPasswordSchema}
            onSubmit={handleSubmit}
            validateOnChange={false}
          >
            <Form className={styles.form} method="post">
              <Header variant="h1" className={styles.title}>
                Forgot password?
              </Header>
              <Text className={styles.description}>
                Don’t worry, we will send you reset instructions.
              </Text>
              <Field name="email" label="Email" autoComplete="email" />
              <Button
                loading={isPending}
                type="submit"
                className="w-full"
                size="large"
              >
                Reset password
              </Button>
            </Form>
          </Formik>
        )}
        <Link to={pathTo('signIn')} className={styles.back}>
          <Icon name={IconName.arrowLeft} />
          Back to login
        </Link>
      </div>
    </div>
  )
}

export { ForgotPassword }
