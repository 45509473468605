import clsx from 'clsx'
import DOMPurify from 'dompurify'
import { uniqueId } from 'lodash'
import { useEffect, useState } from 'react'
import { useMemo } from 'react'
import { useLocation } from 'react-router-dom'
import { Avatar } from 'components/Avatar'
import { EllipsesActions } from 'components/EllipsesActions'
import { Flex } from 'components/Flex'
import { Icon, IconName } from 'components/Icon'
import { Text } from 'components/Text'
import { Tooltip } from 'components/Tooltip'
import { Comment } from 'types'
import { friendlyDateTime } from 'utils/date'
import styles from './styles.module.scss'

interface Props {
  comments: Comment[]
  hidePinIcon?: boolean
  onPin?: (comment: Partial<Comment>) => void
  onUnpin?: (comment: Partial<Comment>) => void
  onView?: (comment: Partial<Comment>) => void
}

const Comments = ({ comments, hidePinIcon, onPin, onUnpin, onView }: Props) => {
  const { hash } = useLocation()
  const uniqInstanceId = useMemo(() => uniqueId(), [])
  const [openEllipsesCommentId, setOpenEllipsesCommentId] = useState<
    string | null
  >(null)
  const isEllipsesActionsVisible = useMemo(
    () => !!onPin || !!onUnpin || !!onView,
    [onPin, onUnpin, onView]
  )
  useEffect(() => {
    if (hash.startsWith('#comments:')) {
      const commentId = hash.split(':')[1]
      const commentElement = document.getElementById(
        `${uniqInstanceId}_${commentId}`
      )
      if (commentElement) {
        commentElement.scrollIntoView({ block: 'center' })
      }
    }
  }, [uniqInstanceId, hash])

  return (
    <Flex stack gap={0}>
      {comments.map((comment) => (
        <Flex
          key={comment.id}
          id={`${uniqInstanceId}_${comment.id}`}
          gap={12}
          className={clsx('group pt-2 px-2', !!onView && 'cursor-pointer')}
          onClick={() => onView?.(comment)}
        >
          <Tooltip content={comment.by.name}>
            <Avatar
              id={comment.by.id}
              name={comment.by.name}
              className="mt-1.5"
            />
          </Tooltip>

          <div className="w-full overflow-x-auto pb-3 px-1">
            <Flex stack gap={6}>
              <Flex gap={4}>
                <div className="font-bold">{comment.by.name}</div>
                {!hidePinIcon && comment.isPinned && (
                  <Icon name={IconName.pin} className="text-yellow-100" />
                )}
              </Flex>
              <div
                className={styles.comment}
                dangerouslySetInnerHTML={{
                  __html: DOMPurify.sanitize(comment.text),
                }}
              />
              <Text variant="s" className="text-grey-600 normal-nums">
                {friendlyDateTime(
                  comment.createdAt,
                  'EEEE MMM d, y @ h:mm aaa'
                )}
              </Text>
            </Flex>
          </div>

          {isEllipsesActionsVisible && (
            <EllipsesActions
              onOpenChange={(isOpen) =>
                setOpenEllipsesCommentId(isOpen ? comment.id : null)
              }
              triggerClassName={clsx(
                'h-7 group-hover:visible',
                comment.id === openEllipsesCommentId ? 'visible' : 'invisible'
              )}
            >
              {!comment.isPinned && onPin ? (
                <EllipsesActions.Item onSelect={() => onPin(comment)}>
                  <Flex alignItems="center" gap={4}>
                    <Icon name={IconName.pin} className="text-yellow-100" />
                    Pin
                  </Flex>
                </EllipsesActions.Item>
              ) : (
                <></>
              )}
              {comment.isPinned && onUnpin ? (
                <EllipsesActions.Item onSelect={() => onUnpin(comment)}>
                  <Flex alignItems="center" gap={4}>
                    <Icon name={IconName.pin} className="text-yellow-100" />
                    Unpin
                  </Flex>
                </EllipsesActions.Item>
              ) : (
                <></>
              )}
              {onView ? (
                <EllipsesActions.Item onSelect={() => onView(comment)}>
                  <Flex alignItems="center" gap={4}>
                    <Icon name={IconName.comments} className="text-grey-600" />
                    View in Comments
                  </Flex>
                </EllipsesActions.Item>
              ) : (
                <></>
              )}
            </EllipsesActions>
          )}
        </Flex>
      ))}
    </Flex>
  )
}

export { Comments }
