import clsx from 'clsx'
import { FieldArray, useFormikContext } from 'formik'
import { compact, isString } from 'lodash'
import { SignatureField } from 'borrower/components/DraftApplication/SignatureField'
import { YesNoField } from 'borrower/components/DraftApplication/YesNoField'
import { getLimitToType } from 'borrower/components/DraftApplication/helpers'
import { Phone, Select, FieldIcon, Date, Textarea } from 'components/Form'
import { CustomFieldProps } from 'components/Form/Field'
import { FormAddress } from 'components/FormAddress'
import { Icon, IconName } from 'components/Icon'
import { Panel } from 'components/Panel'
import { TextLink } from 'components/TextLink'
import styles from 'pages/GuestApplication/styles.module.scss'
import { ApplicationSchemeField, FieldType } from 'types'
import { PersonForm } from './PersonForm'

type Props = CustomFieldProps & {
  prefix?: string
  field: ApplicationSchemeField
}

function ApplicationField({ prefix = '', field }: Props) {
  const name = compact([prefix, field.id]).join('.')
  const form = useFormikContext()

  switch (field.type) {
    case 'option':
      return (
        <Select
          name={name}
          label={field.label}
          options={[{ label: '(blank)', value: '' }, ...(field.options || [])]}
          placeholder={field.placeholder}
          bottomHint={field.description}
          portal
        />
      )
    case 'date':
      return (
        <Date
          name={name}
          label={field.label}
          placeholder={field.placeholder}
          bottomHint={field.description}
        />
      )
    case 'phone':
      return (
        <Phone
          name={name}
          label={field.label}
          placeholder={field.placeholder}
          bottomHint={field.description}
        />
      )
    case 'address':
      return (
        <FormAddress
          className={styles.addressLabels}
          prefix={name}
          heading={field.label}
          description={field.description}
        />
      )
    case 'borrower':
    case 'guarantor':
      return (
        <FieldArray
          name={name}
          render={(arrayHelpers) => {
            return (
              <Panel
                title={field.type === 'borrower' ? 'Borrowers' : 'Guarantors'}
                className={clsx(styles.panel, 'mb-8')}
              >
                {isString(arrayHelpers.form.errors[name]) && (
                  <div className="text-red-100 mb-4">
                    {arrayHelpers.form.errors[field.id] as string}
                  </div>
                )}
                {form.values?.[field.id]?.map((_, index) => (
                  <PersonForm
                    key={index}
                    prefix={`${field.id}.${index}`}
                    className={styles.personForm}
                    field={field}
                    onDelete={
                      (form.values?.[field.id] as any[]).length > 1
                        ? () => arrayHelpers.remove(index)
                        : undefined
                    }
                  />
                ))}
                <div>
                  <TextLink
                    onClick={() =>
                      arrayHelpers.push({
                        type: getLimitToType(field),
                        name: '',
                      })
                    }
                  >
                    <Icon name={IconName.plus} size="sm" />
                    Add {field.type === 'borrower' ? 'Borrower' : 'Guarantor'}
                  </TextLink>
                </div>
              </Panel>
            )
          }}
        />
      )
    case 'document':
      return <div />
    case 'yes-no':
      return (
        <YesNoField
          name={name}
          label={field.label}
          bottomHint={field.description}
        />
      )
    case 'signature':
      return <SignatureField field={field} />
    case 'tax-number':
    case 'ssn-number':
      return (
        <FieldIcon
          type="number"
          name={name}
          mask={field.type === 'ssn-number' ? '000-00-0000' : '00-0000000'}
          placeholder={field.placeholder}
          bottomHint={field.description}
          label={field.label}
        />
      )
    default:
      if (field.multiline) {
        return (
          <Textarea
            name={name}
            placeholder={field.placeholder}
            bottomHint={field.description}
            label={field.label}
          />
        )
      }
      return (
        <FieldIcon
          type={field.type as FieldType}
          name={name}
          placeholder={field.placeholder}
          bottomHint={field.description}
          label={field.label}
        />
      )
  }
}

export { ApplicationField }
