import { Fragment, ReactNode, useCallback, useEffect, useState } from 'react'
import { useSaveMailAttachment } from 'admin/hooks/use-thread-attachments'
import { useThreadContext } from 'admin/pages/Thread/ThreadContext'
import { Button } from 'components/Button'
import { Flex } from 'components/Flex'
import { Icon, IconName } from 'components/Icon'
import { LoanDocument } from 'types'
import { Section } from 'utils/loan-document-sections'
import { Initial } from './Initial'
import { ListOfContactDocuments } from './ListOfContactDocuments'
import { ListOfLoanDocuments } from './ListOfLoanDocuments'
import { Item, ItemWithDocument } from './ModalSaveDocs'

type Step = 'initial' | 'listOfDocuments'

interface Props {
  attachmentId: string
  mailId: string
  setTitle: (title: ReactNode) => void
  onClose: () => void
}

const BrowseDocuments = ({
  attachmentId,
  mailId,
  setTitle,
  onClose,
}: Props) => {
  const [step, setStep] = useState<Step>('initial')
  const [currentItem, setCurrentItem] = useState<Item>()
  const [selectedItem, setSelectedItem] = useState<ItemWithDocument>()
  const { thread } = useThreadContext()
  const { mutate: saveAttachment, isPending: isSaving } =
    useSaveMailAttachment()

  const handleSelectDocument = useCallback(
    (section: Section, document: LoanDocument) => {
      setSelectedItem({
        item: currentItem as Item,
        section: section,
        document: document,
      })
    },
    [setSelectedItem, currentItem]
  )

  const handleSave = useCallback(async () => {
    saveAttachment(
      {
        mailId: mailId,
        document: selectedItem?.document as LoanDocument,
        attachmentId: attachmentId,
        sectionName: selectedItem?.section.name as string,
      },
      { onSuccess: onClose }
    )
  }, [mailId, selectedItem, onClose])

  useEffect(() => {
    if (step === 'initial') {
      setTitle('Save to...')
    }
    if (step === 'listOfDocuments') {
      setTitle(
        <Flex
          gap={12}
          alignItems="center"
          onClick={() => setStep('initial')}
          className="cursor-pointer"
        >
          <Icon name={IconName.arrowLeftLong} className="text-grey-600" />
          <div>{currentItem ? currentItem.name : 'Documents'}</div>
        </Flex>
      )
    }
  }, [step, setTitle, currentItem, selectedItem])

  return (
    <Fragment>
      <div>
        {step === 'initial' && (
          <Initial
            thread={thread}
            onSelect={(person) => {
              setCurrentItem(person)
              setStep('listOfDocuments')
            }}
          />
        )}
        {step === 'listOfDocuments' && currentItem?.type === 'loan' && (
          <ListOfLoanDocuments
            currentItem={currentItem}
            selectedDocument={selectedItem?.document.id as string}
            onSelectDocument={handleSelectDocument}
          />
        )}
        {step === 'listOfDocuments' &&
          (currentItem?.type === 'borrower' ||
            currentItem?.type === 'investor') && (
            <ListOfContactDocuments
              currentItem={currentItem}
              selectedDocument={selectedItem?.document.id as string}
              onSelectDocument={handleSelectDocument}
            />
          )}
      </div>
      <Flex alignItems="center" justifyContent="end" className="mt-7">
        <Flex gap={10}>
          <Button variant="tertiary" onClick={onClose}>
            Cancel
          </Button>
          <Button
            variant="primary"
            loading={isSaving}
            disabled={!selectedItem}
            onClick={handleSave}
          >
            Save
          </Button>
        </Flex>
      </Flex>
    </Fragment>
  )
}

export { BrowseDocuments }
