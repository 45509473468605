import clsx from 'clsx'
import { memo, useMemo } from 'react'
import { useLoanSubstatuses } from 'admin/hooks/use-loan-substatuses'
import { Flex } from 'components/Flex'
import { Indicator } from 'components/Indicator'
import { substatusToColor } from 'constants/substatus'

interface Props {
  substatus: string
  className?: string
}

const SubstatusBadge = memo(({ substatus, className }: Props) => {
  const { data: substatuses } = useLoanSubstatuses()

  const color = useMemo(() => {
    const color = substatuses?.find(({ name }) => name === substatus)?.colour
    return color || substatusToColor(substatus)
  }, [substatuses, substatus])

  return (
    <Flex
      alignItems="center"
      gap={4}
      className={clsx(
        'text-sm px-1.5 py-1 border border-solid border-grey-200 rounded-[5px] font-bold whitespace-nowrap',
        className
      )}
    >
      <Flex alignItems="center" justifyContent="center" className="w-4 h-4">
        <Indicator color={color} className="rounded-[3px]" />
      </Flex>
      {substatus}
    </Flex>
  )
})

SubstatusBadge.displayName = 'SubstatusBadge'

export { SubstatusBadge }
