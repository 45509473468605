import { uniqBy } from 'lodash'
import { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import {
  CommentEditor,
  clearHTML,
  isEmpty,
} from 'admin/components/InlineWysiwyg/CommentEditor'
import { TopMenu } from 'admin/components/TopMenu'
import {
  useAddLoanComment,
  useLoanComments,
  commentsPerPage,
  useUpdateLoanComment,
} from 'admin/hooks/use-loan-comments'
import { useUsers } from 'admin/hooks/use-users'
import { useLoanContext } from 'admin/pages/Loan/LoanContext'
import { Button } from 'components/Button'
import { Comments } from 'components/Comments'
import { Drawer } from 'components/Drawer'
import { Flex } from 'components/Flex'
import { Icon, IconName } from 'components/Icon'
import { Text } from 'components/Text'
import { useSession } from 'hooks/use-session'
import { CurrentUser } from 'services/api/session'
import { Comment } from 'types'

interface Props {
  onClose: () => void
  loanId: string
}

const CommentsDraw = ({ onClose, loanId }: Props) => {
  const messagesContainerRef = useRef<HTMLDivElement>(null)
  const { user } = useSession()
  const [page, setPage] = useState(0)
  const [text, setText] = useState('')
  const { loan, isOrigination, openTasks, openTimeline, handleOwnersChange } =
    useLoanContext()
  const { mutate: updateComment } = useUpdateLoanComment(loanId)
  const [visibleComments, setVisibleComments] = useState<Comment[]>([])
  const [updatedComments, setUpdatedComments] = useState<Comment[]>([])
  const { data: newComments, isLoading } = useLoanComments(
    { loanId },
    {
      refetchInterval: 10000,
    }
  )
  const { data: previousComments, isFetching: isFetchingPreviousComments } =
    useLoanComments(
      {
        loanId,
        params: { page, size: commentsPerPage },
      },
      { enabled: page > 0 }
    )
  const { mutate: comment, isPending: posting } = useAddLoanComment(loanId)
  const { data: users } = useUsers({
    user,
    clientId: (user as CurrentUser)?.client?.id,
  })

  const handlePost = () => {
    if (!isEmpty(text)) {
      comment(clearHTML(text), {
        onSuccess: ({ comments }) => {
          setUpdatedComments(comments)
        },
      })
      setText('')
      messagesContainerRef.current?.scrollTo(0, 0)
    }
  }

  const handleScroll = useCallback(
    (e) => {
      const hasScroll = e.target.scrollHeight > e.target.clientHeight
      const isScrollingToBottom =
        e.target.scrollTop + e.target.clientHeight >=
        e.target.scrollHeight - 100
      const hasMore = (newComments?.total || 0) > visibleComments.length

      if (
        hasScroll &&
        isScrollingToBottom &&
        !isFetchingPreviousComments &&
        hasMore
      ) {
        setPage((page) => page + 1)
      }
    },
    [isFetchingPreviousComments, visibleComments, newComments]
  )

  const mentionUsers = useMemo(
    () =>
      users?.map(({ id, name, email }) => ({ id, value: name, email })) || [],
    [users]
  )

  useEffect(() => {
    const nextComments = uniqBy(
      [
        ...visibleComments,
        ...(newComments?.comments || []),
        ...(previousComments?.comments || []),
        ...updatedComments,
      ].sort(
        (a, b) =>
          new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime()
      ),
      'id'
    )
    setVisibleComments(nextComments)
    setUpdatedComments(updatedComments.length ? [] : updatedComments)
  }, [newComments, previousComments, updatedComments])

  const togglePin = useCallback(
    ({ id, isPinned }: Partial<Comment>) => {
      updateComment(
        { id, isPinned: !isPinned },
        {
          onSuccess: (comment) => {
            setUpdatedComments([comment])
          },
        }
      )
      setVisibleComments((comments) =>
        comments.map((comment) =>
          comment.id === id ? { ...comment, isPinned: !isPinned } : comment
        )
      )
    },
    [visibleComments, updateComment]
  )

  return (
    <Drawer onClose={onClose} loading={isLoading} className="px-4">
      <Drawer.Header className="pl-2.5 pr-0 -mx-4">
        <Flex
          justifyContent="space-between"
          alignItems="center"
          className="h-full"
        >
          <Button variant="ghost" onClick={onClose} className="w-7 h-7">
            <Icon name={IconName.doubleArrowRight} className="text-black-100" />
          </Button>
          <TopMenu
            owners={loan.owners}
            onOwnersChange={handleOwnersChange}
            onHistoryClick={isOrigination ? undefined : openTimeline}
            onTasksClick={openTasks}
            messagesCount={newComments?.total}
            onCommentsClick={onClose}
          />
        </Flex>
      </Drawer.Header>
      <Drawer.Content
        ref={messagesContainerRef}
        className="w-auto mt-4 pt-0 pl-0 pr-4 mr-[-14px]"
        onScroll={handleScroll}
      >
        <div className="sticky top-0 bg-white-100">
          <Flex
            stack
            gap={12}
            className="p-3 border border-solid border-grey-200 rounded-xl"
          >
            <Flex gap={4}>
              <Icon name={IconName.message} className="text-black-100" />
              <Text>Comment</Text>
            </Flex>
            <CommentEditor
              namespace="body"
              users={mentionUsers}
              placeholder="Write a comment and @mention teammates. This is only visible to your internal team."
              value={text}
              dropdownPosition="bottom"
              onChange={setText}
              onEnter={handlePost}
            />
            <Flex justifyContent="flex-end">
              <Button
                onClick={handlePost}
                loading={posting}
                variant={isEmpty(text) ? 'tertiary' : 'primary'}
                disabled={isEmpty(text)}
                className="p-2"
              >
                <Icon name={IconName.moveUp} />
              </Button>
            </Flex>
          </Flex>
        </div>
        <div className="px-0 pt-5 pb-4">
          {visibleComments.length === 0 ? (
            <Flex
              alignItems="center"
              justifyContent="center"
              className="h-full"
            >
              <Text>No comments yet</Text>
            </Flex>
          ) : (
            <Comments
              comments={visibleComments}
              onPin={togglePin}
              onUnpin={togglePin}
            />
          )}
        </div>
      </Drawer.Content>
    </Drawer>
  )
}

export { CommentsDraw }
