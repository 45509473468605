import {
  useQuery,
  keepPreviousData,
  useMutation,
  useQueryClient,
} from '@tanstack/react-query'
import { size } from 'lodash'
import { KEY_THREADS } from 'constants/query-keys'
import { handleErrorResponse } from 'services/request'
import { Filter, IThread, IThreadTab, Pagination } from 'types'
import {
  getThreads,
  updateThreads,
  getThread,
  addThread,
  updateThread,
  removeThread,
  addThreadMail,
  removeThreadMail,
  sendThreadMail,
  updateThreadMail,
  getThreadMail,
} from '../services/api/threads'

const useThreads = (
  {
    search,
    pagination,
    folder,
    loanId,
    filter,
    include,
    exclude,
    checkMail = false,
  }: {
    folder?: IThreadTab
    search?: string
    pagination?: Pagination
    loanId?: string
    filter?: Filter
    include?: string[]
    exclude?: string[]
    checkMail?: boolean
  } = {},
  options = {}
) => {
  const nextFilter = size(filter) ? filter : undefined
  return useQuery({
    queryKey: [
      KEY_THREADS,
      folder,
      search,
      pagination,
      loanId,
      nextFilter,
      include?.join(''),
      exclude?.join(''),
      checkMail && (!pagination || pagination.page === 0),
    ],
    queryFn: () =>
      getThreads({
        folder,
        search,
        page: pagination,
        filter: nextFilter,
        loanId,
        include,
        exclude,
        checkMail: checkMail && (!pagination || pagination.page === 0),
      }),
    placeholderData: keepPreviousData,
    ...options,
  })
}

const useThreadMail = ({ mailId }: { mailId: string }, options = {}) => {
  return useQuery({
    queryKey: [KEY_THREADS, 'mail', mailId],
    queryFn: () => getThreadMail({ mailId }),
    ...options,
  })
}

const useUpdateThreads = () => {
  const queryClient = useQueryClient()
  return useMutation({
    mutationFn: updateThreads,
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: [KEY_THREADS] })
    },
    onError: handleErrorResponse,
  })
}

const useThread = ({ id }: { id: string }) => {
  return useQuery({
    queryKey: [KEY_THREADS, id],
    queryFn: () => getThread(id),
  })
}

const useAddThread = () => {
  const queryClient = useQueryClient()
  return useMutation({
    mutationFn: addThread,
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: [KEY_THREADS] })
    },
    onError: handleErrorResponse,
  })
}

const useAddThreadMail = () => {
  const queryClient = useQueryClient()
  return useMutation({
    mutationFn: addThreadMail,
    onSuccess: ({ thread }) => {
      queryClient.setQueryData([KEY_THREADS, thread.id], thread)
    },
    onError: handleErrorResponse,
  })
}

const useSendThreadMail = () => {
  const queryClient = useQueryClient()
  return useMutation({
    mutationFn: sendThreadMail,
    onSuccess: ({ thread }) => {
      queryClient.setQueryData([KEY_THREADS, thread.id], thread)
    },
    onError: handleErrorResponse,
  })
}

const useUpdateThread = () => {
  const queryClient = useQueryClient()
  return useMutation({
    mutationFn: updateThread,
    onSuccess: (thread) => {
      queryClient.setQueryData([KEY_THREADS, thread.id], thread)
    },
    onError: handleErrorResponse,
  })
}

const useUpdateThreadMail = () => {
  const queryClient = useQueryClient()
  return useMutation({
    mutationFn: updateThreadMail,
    onSuccess: ({ thread, mail }) => {
      queryClient.setQueryData([KEY_THREADS, 'mail', mail.id], mail)
      const currentThread: IThread =
        queryClient.getQueryData([KEY_THREADS, thread.id]) || thread
      const mailIndex = currentThread.mails.findIndex(
        ({ id }) => id === mail.id
      )
      currentThread.mails[mailIndex] = mail
      queryClient.setQueryData([KEY_THREADS, thread.id], currentThread)
    },
    onError: handleErrorResponse,
  })
}

const useRemoveThread = () => {
  const queryClient = useQueryClient()
  return useMutation({
    mutationFn: removeThread,
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: [KEY_THREADS] })
    },
    onError: handleErrorResponse,
  })
}

const useRemoveThreadMail = () => {
  const queryClient = useQueryClient()
  return useMutation({
    mutationFn: ({ mailId }: { threadId: string; mailId: string }) =>
      removeThreadMail(mailId),
    onMutate: ({ threadId, mailId }) => {
      const currentThread = queryClient.getQueryData([
        KEY_THREADS,
        threadId,
      ]) as IThread
      queryClient.setQueryData([KEY_THREADS, currentThread?.id], {
        ...currentThread,
        mails: currentThread.mails.filter(({ id }) => id !== mailId),
      })
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: [KEY_THREADS] })
    },
    onError: handleErrorResponse,
  })
}

export {
  useThreads,
  useThreadMail,
  useUpdateThreads,
  useThread,
  useAddThread,
  useAddThreadMail,
  useUpdateThread,
  useRemoveThread,
  useRemoveThreadMail,
  useSendThreadMail,
  useUpdateThreadMail,
}
