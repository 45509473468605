import { parseISO } from 'date-fns'
import { Formik } from 'formik'
import { uniqBy, find } from 'lodash'
import { useMemo, useState } from 'react'
import { Button } from 'components/Button'
import { Form, Date, LoanField } from 'components/Form'
import { Grid } from 'components/Grid'
import { Modal } from 'components/Modal'
import { FieldType } from 'types'
import { createScheme, required } from 'utils/schemas'
import styles from './styles.module.scss'
import type { ITemplate } from 'types/ITemplate'

interface IPayoffStatement {
  date: string
  expirationDate: string
}

interface Props {
  dateClosing: string
  template: ITemplate
  onSubmit: (values: any) => void
  onCancel: () => void
}

function ModalGeneratePayoff({
  dateClosing,
  template,
  onSubmit,
  onCancel,
}: Props) {
  const [payoffStatement, setPayoffStatement] = useState<IPayoffStatement>({
    date: '',
    expirationDate: '',
  })
  const isPayoff = useMemo(() => template.type === 'payoff', [template.type])
  const includePayoff = useMemo(() => {
    const mergeTags = template.mergeTags ?? []
    return (
      isPayoff ||
      !!find(mergeTags, (tag) => tag.toLowerCase() === 'payoff_date')
    )
  }, [isPayoff])
  const missingMergeTags = useMemo(
    () =>
      uniqBy(template.missingMergeTags || [], 'tag').filter(
        ({ tag }) =>
          !includePayoff ||
          (includePayoff &&
            tag.toLowerCase() !== 'payoff_date' &&
            tag.toLowerCase() !== 'expiration_date')
      ),
    [template.missingMergeTags, includePayoff]
  )

  const Schema = useMemo(() => {
    const mergeFieldsSchema = missingMergeTags.reduce(
      (acc, { tag }) => ({ ...acc, [tag]: required }),
      {}
    )

    return createScheme({
      ...(isPayoff ? { date: required, expirationDate: required } : {}),
      ...mergeFieldsSchema,
    })
  }, [missingMergeTags, isPayoff])

  const initialValues = useMemo(() => {
    const mergeFieldsInitialValues = missingMergeTags.reduce(
      (acc, { tag }) => ({ ...acc, [tag]: '' }),
      {}
    )
    return {
      date: '',
      expirationDate: '',
      ...mergeFieldsInitialValues,
    }
  }, [missingMergeTags])

  return (
    <Modal
      title={isPayoff ? 'Payoff Statement' : template.name}
      onClose={onCancel}
      className={styles.modal}
    >
      <Formik
        initialValues={initialValues}
        validationSchema={Schema}
        enableReinitialize
        onSubmit={onSubmit}
      >
        <Form>
          <Grid className="mt-4" columnGap={16}>
            {missingMergeTags.map(({ tag, type }) => (
              <Grid.Item xs={6} key={tag}>
                <LoanField
                  field={{
                    id: tag,
                    name: tag,
                    type: [type as FieldType],
                    mask: null,
                    fullAccuracy: false,
                    options: null,
                  }}
                  label={tag.replace(/_/g, ' ')}
                  type={type as FieldType}
                />
              </Grid.Item>
            ))}
            {includePayoff && (
              <>
                <Grid.Item xs={6}>
                  <Date
                    label="Payoff Date"
                    name="date"
                    minDate={dateClosing}
                    onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                      setPayoffStatement((payoffStatement) => ({
                        date: event.target.value,
                        expirationDate:
                          parseISO(payoffStatement.expirationDate).getTime() <
                          parseISO(event.target.value).getTime()
                            ? event.target.value
                            : payoffStatement.expirationDate,
                      }))
                    }
                  />
                </Grid.Item>
                <Grid.Item xs={6}>
                  <Date
                    label="Expiration Date"
                    name="expirationDate"
                    minDate={payoffStatement.date}
                    onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                      setPayoffStatement((payoffStatement) => ({
                        ...payoffStatement,
                        expirationDate: event.target.value,
                      }))
                    }
                  />
                </Grid.Item>
              </>
            )}
            <Grid.Item xs={12} className="flex justify-end gap-2.5">
              <Button variant="tertiary" onClick={onCancel}>
                Cancel
              </Button>
              <Button type="submit">Generate</Button>
            </Grid.Item>
          </Grid>
        </Form>
      </Formik>
    </Modal>
  )
}

export default ModalGeneratePayoff
