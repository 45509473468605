import { DndProvider } from 'react-dnd'
import { HTML5Backend } from 'react-dnd-html5-backend'
import { PageTop } from 'admin/components/PageTop'
import { MainContent } from 'admin/components/layout/MainContent'
import { useLoanSubstatuses } from 'admin/hooks/use-loan-substatuses'
import { pathTo } from 'admin/path-to'
import { Flex } from 'components/Flex'
import { loanStatusOptions } from 'constants/loan-status'
import { PanelStatus } from './PanelStatus'

const SettingsPipeline = () => {
  const { data: substatuses, isPending } = useLoanSubstatuses()

  return (
    <MainContent>
      <Flex stack gap={16}>
        <PageTop
          breadcrumbs={{ title: 'Settings', link: pathTo('settings') }}
          title="Pipeline"
        />
        <Flex flexDirection="column">
          <DndProvider backend={HTML5Backend}>
            {loanStatusOptions.map((status) => (
              <PanelStatus
                key={status.value}
                status={status}
                substatuses={substatuses}
                isLoading={isPending}
              />
            ))}
          </DndProvider>
        </Flex>
      </Flex>
    </MainContent>
  )
}

export { SettingsPipeline }
