import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import { size } from 'lodash'
import {
  getLoanComments,
  addLoanComment,
  updateLoanComment,
} from 'admin/services/api/loan-comments'
import { KEY_LOAN_COMMENTS } from 'constants/query-keys'
import { handleErrorResponse } from 'services/request'
import { Filter, Comment } from 'types'

const commentsPerPage = 25

const useLoanComments = (
  {
    loanId,
    filter,
    params = { page: 0, size: commentsPerPage },
  }: {
    loanId: string
    filter?: Filter
    params?: { page: number; size: number }
  },
  options: {
    enabled?: boolean
    refetchInterval?: number
  } = {}
) => {
  const nextFilter = size(filter) ? filter : undefined
  return useQuery({
    queryKey: [KEY_LOAN_COMMENTS, loanId, nextFilter, params.page, params.size],
    queryFn: () => getLoanComments(loanId, { ...params, filter }),
    staleTime: 10000,
    ...options,
  })
}

const useAddLoanComment = (loanId: string) => {
  const queryClient = useQueryClient()
  return useMutation({
    mutationFn: (text: string) => addLoanComment(loanId, { text }),
    onSuccess: ({ comments, meta }) => {
      queryClient.setQueryData(
        [KEY_LOAN_COMMENTS, loanId, undefined, meta.page, meta.pageSize],
        {
          comments,
          total: meta.total,
        }
      )
    },
    onError: handleErrorResponse,
  })
}

const useUpdateLoanComment = (loanId: string) => {
  const queryClient = useQueryClient()
  return useMutation({
    mutationFn: (payload: Partial<Comment>) =>
      updateLoanComment(loanId, payload),
    onSuccess: (savedComment, payloadComment) => {
      const pinnedComments = queryClient.getQueryData([
        KEY_LOAN_COMMENTS,
        loanId,
        { isPinned: [true] },
        0,
        100,
      ]) as { comments: Comment[]; total: number }
      if (
        !!pinnedComments?.comments?.length &&
        payloadComment?.isPinned === false &&
        savedComment.isPinned === false
      ) {
        const nextPinnedComments = pinnedComments.comments.filter(
          ({ id }) => id !== savedComment.id
        )
        queryClient.setQueryData(
          [KEY_LOAN_COMMENTS, loanId, { isPinned: [true] }, 0, 100],
          {
            comments: nextPinnedComments,
            total: nextPinnedComments.length,
          }
        )
      }
      queryClient.invalidateQueries({
        queryKey: [KEY_LOAN_COMMENTS, loanId, { isPinned: [true] }],
      })
    },
    onError: handleErrorResponse,
  })
}

export {
  useLoanComments,
  useAddLoanComment,
  useUpdateLoanComment,
  commentsPerPage,
}
