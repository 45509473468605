import { request } from 'services/request'
import { Comment, Filter, Meta } from 'types'

const getLoanComments = async (
  id: string,
  { filter, page, size }: { filter?: Filter; page: number; size: number }
): Promise<{ comments: Comment[]; total: number }> => {
  const {
    data: {
      comments,
      pagination: { total },
    },
  } = await request.get(`/loan/${id}/comments`, {
    params: {
      page: { size, page },
      filter,
      sort: ['created_at desc'],
    },
  })
  return { comments: comments, total }
}

const addLoanComment = async (
  id: string,
  data: { text: string }
): Promise<{ comments: Comment[]; meta: Meta }> => {
  const {
    data: { comments, pagination: meta },
  } = await request.post(`/loan/${id}/comments`, data, {
    params: {
      page: {
        size: 25,
        page: 0,
      },
      sort: ['created_at desc'],
    },
  })
  return { comments, meta }
}

const updateLoanComment = async (
  loanId: string,
  payload: Partial<Comment>
): Promise<Comment> => {
  const {
    data: { comment },
  } = await request.patch(`/loan/${loanId}/comments/${payload.id}`, payload)
  return comment
}

export { getLoanComments, addLoanComment, updateLoanComment }
