import { $generateHtmlFromNodes, $generateNodesFromDOM } from '@lexical/html'
import { AutoFocusPlugin } from '@lexical/react/LexicalAutoFocusPlugin'
import { LexicalComposer } from '@lexical/react/LexicalComposer'
import { ContentEditable } from '@lexical/react/LexicalContentEditable'
import LexicalErrorBoundary from '@lexical/react/LexicalErrorBoundary'
import { OnChangePlugin } from '@lexical/react/LexicalOnChangePlugin'
import { RichTextPlugin } from '@lexical/react/LexicalRichTextPlugin'
import clsx from 'clsx'
import { $getRoot, LexicalEditor } from 'lexical'
import {
  BeautifulMentionsPlugin,
  BeautifulMentionNode,
  BeautifulMentionsMenuProps,
  BeautifulMentionsMenuItemProps,
  BeautifulMentionsTheme,
} from 'lexical-beautiful-mentions'
import { uniqueId } from 'lodash'
import { useState, useCallback, forwardRef } from 'react'
import { Avatar } from 'components/Avatar'
import { Flex } from 'components/Flex'
import { Text } from 'components/Text'
import { ClearPlugin } from './plugins/ClearPlugin'
import { EnterPlugin } from './plugins/EnterPlugin'
import styles from './styles.module.scss'
import { isEmpty, clearHTML } from './utils/helpers'

const beautifulMentionsTheme: BeautifulMentionsTheme = {
  '@': 'text-blue-100 font-bold',
  '@Focused': '!text-blue-200',
}

interface Props {
  value: string
  users: { value: string; id: string; email: string }[]
  namespace?: string
  disabled?: boolean
  placeholder: string
  dropdownPosition?: 'top' | 'bottom'
  onChange: (html: string) => void
  onEnter: () => void
}

function CommentEditor({
  namespace = uniqueId('editor-'),
  users,
  placeholder,
  disabled,
  value,
  dropdownPosition = 'top',
  onChange,
  onEnter,
}: Props) {
  const [isMentionMenuOpen, setIsMentionMenuOpen] = useState(false)

  const initialConfig = {
    namespace,
    editable: !disabled,
    theme: { beautifulMentions: beautifulMentionsTheme },
    onError: () => {},
    editorState: (editor) => {
      const parser = new DOMParser()
      const dom = parser.parseFromString(value || '<p></p>', 'text/html')
      const nodes = $generateNodesFromDOM(editor, dom)
      $getRoot().append(...nodes)
    },
    nodes: [BeautifulMentionNode],
  }

  const handleChange = useCallback(
    (editorState, editor: LexicalEditor) => {
      editor.update(() => {
        const htmlString = $generateHtmlFromNodes(editor, null)
        onChange?.(htmlString)
      })
    },
    [onChange]
  )

  function Menu(props: BeautifulMentionsMenuProps) {
    return (
      <Flex
        stack
        gap={0}
        className={clsx(
          'absolute z-10 rounded border border-solid border-grey-100 bg-white-100 p-1',
          dropdownPosition === 'top' ? 'bottom-10' : 'top-0'
        )}
      >
        <Text variant="s" className="text-grey-700 px-2 py-2">
          Mention a team member
        </Text>
        <div className="min-w-60 max-h-48 -mx-1 overflow-auto" {...props} />
      </Flex>
    )
  }

  const MenuItem = forwardRef<HTMLDivElement, BeautifulMentionsMenuItemProps>(
    (
      {
        selected,
        id,
        children,
        ...props
      }: Omit<BeautifulMentionsMenuItemProps, 'ref'>,
      ref
    ) => {
      return (
        <div
          ref={ref}
          className={clsx(
            'relative flex cursor-pointer select-none items-center rounded-sm px-2 mx-1 py-1.5 outline-none gap-2',
            selected && 'bg-grey-75'
          )}
          {...props}
        >
          <Avatar id={id} name={children} />
          {children}
        </div>
      )
    }
  )
  MenuItem.displayName = 'EditorMenuItem'

  return (
    <LexicalComposer initialConfig={initialConfig}>
      <RichTextPlugin
        contentEditable={
          <div className={styles.editorContent}>
            <ContentEditable
              data-placeholder={placeholder}
              className={clsx(styles.editorInput, styles.commentEditorInput)}
            />
          </div>
        }
        placeholder={<></>}
        ErrorBoundary={LexicalErrorBoundary}
      />
      <BeautifulMentionsPlugin
        items={{ '@': users }}
        menuComponent={Menu}
        menuItemComponent={MenuItem}
        creatable={false}
        menuItemLimit={false}
        onMenuOpen={() => setIsMentionMenuOpen(true)}
        onMenuClose={() => setIsMentionMenuOpen(false)}
      />
      {!isMentionMenuOpen && <EnterPlugin onSubmit={onEnter} />}
      <ClearPlugin text={value} />
      <OnChangePlugin onChange={handleChange} />
      <AutoFocusPlugin defaultSelection="rootEnd" />
    </LexicalComposer>
  )
}

export { CommentEditor, isEmpty, clearHTML }
