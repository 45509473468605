import { useMutation, useQueryClient } from '@tanstack/react-query'
import { useEffect, useState } from 'react'
import { getThreadMail } from 'admin/services/api/threads'
import { KEY_THREADS } from 'constants/query-keys'
import { useWebSocket } from 'hooks/use-websocket'
import { uploadDocument } from 'services/api/document'
import { handleErrorResponse } from 'services/request'
import { IMail } from 'types'
import { message } from 'utils/message'
import {
  addMailAttachment,
  removeMailAttachment,
  saveMailAttachment,
} from '../services/api/thread-attachments'

const useAddMailAttachment = () => {
  const queryClient = useQueryClient()
  return useMutation({
    mutationFn: addMailAttachment,
    onSuccess: ({ thread }) => {
      queryClient.setQueryData([KEY_THREADS, thread.id], thread)
      queryClient.invalidateQueries({ queryKey: [KEY_THREADS] })
    },
    onError: handleErrorResponse,
  })
}

const useRemoveMailAttachment = () => {
  const queryClient = useQueryClient()
  return useMutation({
    mutationFn: removeMailAttachment,
    onSuccess: ({ thread }) => {
      queryClient.setQueryData([KEY_THREADS, thread.id], thread)
      queryClient.invalidateQueries({ queryKey: [KEY_THREADS] })
    },
    onError: handleErrorResponse,
  })
}

const useUploadAttachment = ({
  mail,
  onUpload,
}: {
  mail: IMail
  onUpload?: () => void
}) => {
  const queryClient = useQueryClient()
  const { lastMessage } = useWebSocket()
  const [uploadToasts, setUploadToasts] = useState<any[]>([])
  const [uploaded, setUploaded] = useState(false)

  useEffect(() => {
    if (uploaded) {
      if (lastMessage?.type === 'mail-attachment-added') {
        queryClient.invalidateQueries({ queryKey: [KEY_THREADS] })
        uploadToasts.map((uploadToast) => uploadToast.complete())
        onUpload?.()
      }
      const timeout = setTimeout(() => {
        queryClient.invalidateQueries({ queryKey: [KEY_THREADS] })
        uploadToasts.map((uploadToast) => uploadToast.complete())
        onUpload?.()
      }, 5000)

      return () => clearTimeout(timeout)
    }
  }, [uploaded, uploadToasts, lastMessage])

  return useMutation({
    mutationFn: async (files: FileList) => {
      await Promise.all(
        Array.from(files).map(async (file) => {
          const uploadToast = message.upload(file.name)
          setUploadToasts((uploadToasts) => [...uploadToasts, uploadToast])
          uploadToast.show()
          const mailWithUrl = await getThreadMail({
            mailId: mail.id,
            filename: file.name,
          })
          await uploadDocument(mailWithUrl.uploadUrl, file, (progressEvent) => {
            uploadToast.progress(progressEvent.loaded / progressEvent.total)
          })
          uploadToast.processing()
          return true
        })
      )
      setUploaded(true)
    },
    onError: handleErrorResponse,
  })
}

const useSaveMailAttachment = () => {
  return useMutation({
    mutationFn: saveMailAttachment,
    onSuccess: (_, payload) => {
      message.success(
        `File saved to: ${payload.sectionName} / ${payload.document.name}`
      )
    },
    onError: handleErrorResponse,
  })
}

export {
  useAddMailAttachment,
  useRemoveMailAttachment,
  useUploadAttachment,
  useSaveMailAttachment,
}
