import { Can } from 'admin/components/Abilities'
import { useAbilitiesContext } from 'admin/components/Abilities/AbilitiesContext'
import { PanelEntityBanking } from 'admin/components/PanelEntityBanking'
import { useUpdateLoanBanking } from 'admin/hooks/use-loan-banking'
import { PinnedComments } from 'admin/pages/Loan/PinnedComments'
import { Grid } from 'components/Grid'
import { PanelLoanFields } from 'components/PanelLoanFields'
import { Loan } from 'types'
import PanelClosingAgent from './PanelClosingAgent'
import PanelSpreadAllocation from './PanelSpreadAllocation'
import PanelTranches from './PanelTranches'
import styles from './styles.module.scss'

interface Props {
  loan: Loan
}

function TabGeneralOriginator({ loan }: Props) {
  const ability = useAbilitiesContext()
  const { mutate: updateBanking, isPending: savingBanking } =
    useUpdateLoanBanking(loan.id)

  const showInvestors = ability.can('read', 'investors')
  const showVendors = ability.can('read', 'vendors')

  return (
    <div className={styles.tabContent}>
      <Grid gap={16}>
        <Grid.Item sm={6} className={styles.panels}>
          <PanelLoanFields
            loan={loan}
            title="Terms"
            sections={['Terms', 'Parameters']}
            context="origination"
          />
          <PanelLoanFields
            loan={loan}
            title="Statistics"
            sections={['Statistics']}
            context="origination"
          />
          <PanelLoanFields
            loan={loan}
            title="Collateral Summary"
            sections={['Collateral Summary']}
            context="origination"
          />
        </Grid.Item>
        <Grid.Item sm={6} className={styles.panels}>
          <PinnedComments loan={loan} />
          <PanelLoanFields
            loan={loan}
            sections={['Payment']}
            context="origination"
          />
          <PanelTranches loan={loan} />
          <Can do="read" on="banking">
            <PanelEntityBanking
              banking={loan.banking}
              lock={
                loan.lock && !['liquidated', 'closed'].includes(loan.status)
              }
              updateBanking={updateBanking}
              isSaving={savingBanking}
            />
          </Can>
          {showVendors && <PanelClosingAgent loan={loan} />}
          {showInvestors && showVendors && (
            <PanelSpreadAllocation loan={loan} />
          )}
        </Grid.Item>
      </Grid>
    </div>
  )
}

export default TabGeneralOriginator
